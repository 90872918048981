import { createActionGroup, props } from '@ngrx/store';
import { UserType, User_Permission } from 'src/app/shared/models';

export const PermissionActions = createActionGroup({
  source: 'Permission',
  events: {
    'Load Permissions': props<{
      userId: string;
      userType: UserType;
      tags?: string[];
    }>(),
    'Load Permissions Success': props<{
      permissions: User_Permission[];
    }>(),
    'Load Permissions Failure': props<{ error: Error }>(),
    'Load Permission': props<{
      userType: UserType;
      granterId: string;
      grantedId: string;
    }>(),
    'Load Permission Success': props<{ permission: User_Permission }>(),
    'Load Permission Failure': props<{ error: Error }>(),
    'Update Permission': props<{
      granterId: string;
      grantedUserType?: UserType;
      grantedId: string;
      permission: User_Permission;
      password?: string;
    }>(),
    'Update Permission Success': props<{
      granterId: string;
      grantedId: string;
      permission: User_Permission;
    }>(),
    'Update Permission Failure': props<{ error: Error }>(),
  },
});
