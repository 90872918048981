import { createFeature, createReducer, on } from '@ngrx/store';
import { StaticActions } from './static.actions';
import { Carrier, PermanentTag } from 'src/app/shared/models';

export const staticFeatureKey = 'static';

export interface StaticState {
  carriers: Carrier[];
  tags: { [agencyId: string]: PermanentTag[] };
  loading: boolean;
  error: Error | null;
}

export const initialState: StaticState = {
  carriers: [],
  tags: {},
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    StaticActions.loadStaticCarriers,
    (state): StaticState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    StaticActions.loadStaticCarriersSuccess,
    (state, { carriers }): StaticState => ({
      ...state,
      carriers,
      loading: false,
      error: null,
    })
  ),
  on(
    StaticActions.loadStaticCarriersFailure,
    (state, { error }): StaticState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    StaticActions.loadStaticTags,
    (state): StaticState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    StaticActions.loadStaticTagsSuccess,
    (state, { agencyId, tags }): StaticState => ({
      ...state,
      tags: {
        ...state.tags,
        [agencyId]: tags,
      },
      loading: false,
      error: null,
    })
  ),
  on(
    StaticActions.loadStaticTagsFailure,
    (state, { error }): StaticState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    StaticActions.updateStaticTag,
    (state): StaticState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    StaticActions.updateStaticTagSuccess,
    (state): StaticState => ({
      ...state,
      loading: false,
      error: null,
    })
  ),
  on(
    StaticActions.updateStaticTagFailure,
    (state, { error }): StaticState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    StaticActions.addStaticTag,
    (state): StaticState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    StaticActions.addStaticTagSuccess,
    (state, { agencyId, tag }): StaticState => ({
      ...state,
      tags: {
        ...state.tags,
        [agencyId]: [...(state.tags[agencyId] || []), tag],
      },
      loading: false,
      error: null,
    })
  ),
  on(
    StaticActions.addStaticTagFailure,
    (state, { error }): StaticState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const staticFeature = createFeature({
  name: staticFeatureKey,
  reducer,
});
